import React from 'react';

const Psychotest = () => {

let answers = {'1':null,'2':null,'3':null,'4':null,'5':null,'6':null}

  function setAnswer(e) {

    let qlen = document.getElementsByClassName(e.target.className).length
    for (let i=0;i<qlen;i++){
      document.getElementsByClassName(e.target.className)[i].checked = false
    }
    e.target.checked = true;
    answers[e.target.className] = e.target.value
    //add scrolling effect
    if(Number(e.target.className)<document.getElementsByClassName('qcont').length){
      let x = document.createElement('a')
      x.href = '#q'+(Number(e.target.className)+1)
      x.click()
    }

  }





  function checkAnswers() {
    if
    ((answers['1'] !== null) &&
    (answers['2'] !== null) &&
    (answers['3'] !== null) &&
    (answers['4'] !== null) &&
    (answers['5'] !== null) &&
    (answers['6'] !== null))
    {
    console.log(answers)

    document.getElementById('answers').style.display = 'block'

    for (let i=0;i<document.getElementsByClassName('test-answer').length;i++){
      document.getElementsByClassName('test-answer')[i].style.display = 'none'
    }

    /* Maths:
    Muhsroom1:
    A D A B B A
    Muhsroom2:
    B B C A D C
    Muhsroom3:
    C C B D A D
    Muhsroom4:
    D A D C C B
    */

    // SET MUSHROOM ACCORDING TO ANSWERS
      if
      (answers['1'] === 'A')
      {
        document.getElementById('an1').style.display = 'block'
      }
      else if
        (answers['1'] === 'B')
      {
        document.getElementById('an2').style.display = 'block'
      }
      else if
        (answers['1'] === 'C')
      {
        document.getElementById('an3').style.display = 'block'
      }
      else if
       (answers['1'] === 'D')
     {
       document.getElementById('an4').style.display = 'block'
     }

     //SHOW restart button
     document.getElementById('retake').style.display = 'block'

    } else {
      alert('Please select all answers first...')
    }
  }


  function clearTest(){
    for (let i=0;i<document.getElementsByTagName('input').length;i++){
      document.getElementsByTagName('input')[i].checked = false
    }
    answers = {'1':null,'2':null,'3':null,'4':null,'5':null,'6':null}
    document.getElementById('retake').style.display = 'none'

    for (let i=0;i<document.getElementsByClassName('test-answer').length;i++){
      document.getElementsByClassName('test-answer')[i].style.display = 'none'
    }
    document.getElementById('answers').style.display = 'none'

  }



  return (
    <>
    <section id="psychotest" className="hero is-white" style={{backgroundColor:'#E8D3D0',marginTop:'-30px'}}>
    <div className="hero-head has-text-centered" style={{paddingTop:'0px'}}>
    <nav className="tabs is-boxed is-fullwidth">
      <div className="container">
        <ul className="second-menu">
          <li>
            <a href="#q1">1</a>
          </li>
          <li>
            <a href="#q2">2</a>
          </li>
          <li>
            <a href="#q3">3</a>
          </li>
          <li>
            <a href="#q4">4</a>
          </li>
          <li>
            <a href="#q5">5</a>
          </li>
          <li>
            <a href="#q6">6</a>
          </li>
        </ul>
      </div>
    </nav>
    <br />
    <h1 className="title">Psychotest</h1>
    <h2 className="subtitle" style={{maxWidth:'90%',marginLeft:'5%'}}>Odpowiedz na pytania by odkryć, który gatunek grzyba do Ciebie pasuje!</h2>
    </div>

    <br />
    <br />
    <hr id="q1" />
    <br />
    <br />
      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Pytanie 1:<br /> Co lubisz robić w swoim wolnym czasie?</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="1" onInput={setAnswer} type="checkbox" name="1A" id="1A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="1A"> a) Układać domino z kostek czekolady - to nie tylko dobre dla umysłu, ale również pyszne!</label>
          </div>

          <div className="control">
          <input  className="1" onInput={setAnswer} type="checkbox" name="1B" id="1B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="1B"> b) Trenować skoki przez przeszkody krokiem pingwina - bo czemu nie?</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="1" onInput={setAnswer} type="checkbox" name="1C" id="1C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="1C">c) Uczyć języka migowego swojego kota - w końcu to takie łatwe!</label>
          </div>

          <div className="control">
          <input  className="1" onInput={setAnswer} type="checkbox" name="1D" id="1D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="1D">d) Organizować spotkania z kosmitami na dachu - jeśli już tu jesteśmy, to dlaczego nie mielibyśmy się przywitać?</label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q2"/>
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Pytanie 2:<br /> Jest 2 w nocy, a u sąsiada impreza trwa w najlepsze. Co robisz? </h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="2" onInput={setAnswer} type="checkbox" name="2A" id="2A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="2A"> a) Przyłączam się do imprezy - w końcu nie codziennie zdarza się taka okazja!</label>
          </div>

          <div className="control">
          <input  className="2" onInput={setAnswer} type="checkbox" name="2B" id="2B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="2B"> b) Dzwonię na policję.</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="2" onInput={setAnswer} type="checkbox" name="2C" id="2C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="2C">c) Rano zostawiam pasywno-agresywną kartkę, która wyraża moją złość, ale nie naraża mnie na bezpośrednią konfrontację. Uważam, żeby nie zostawić na niej śladów swojego DNA.</label>
          </div>

          <div className="control">
          <input  className="2" onInput={setAnswer} type="checkbox" name="2D" id="2D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="2D">d) Udaję, że to się po prostu nie dzieje - nic nie zmąci mojego spokoju. Od czego są zatyczki do uszu i poduszka.</label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q3" />
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Pytanie 3:<br /> Co zwykle jesz na śniadanie?</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="3" onInput={setAnswer} type="checkbox" name="3A" id="3A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="3A">a) Jajko poché z wolnego wybiegu z organicznym awokado z certyfikatem fair-trade na chrupiącej, żytniej grzance z chleba na zakwasie z lokalnej mikro piekarni.</label>
          </div>

          <div className="control">
          <input  className="3" onInput={setAnswer} type="checkbox" name="3B" id="3B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="3B">b) Owsianka z siemieniem lnianym z gotowanymi owocami – tak, jak zalecił mój dietoterapeuta.</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="3" onInput={setAnswer} type="checkbox" name="3C" id="3C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="3C">c) Czarny chleb i czarna kawa.</label>
          </div>

          <div className="control">
          <input  className="3" onInput={setAnswer} type="checkbox" name="3D" id="3D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="3D">d) Nie ważne, byleby było konkretne i smaczne. Powietrzem i trawą się nie najem.</label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q4" />
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Pytanie 4:<br /> Jaki jest Twój styl zbierania grzybów:</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="4" onInput={setAnswer} type="checkbox" name="4A" id="4A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="4A">a) Kupuję pieczarki i boczniaki w supermarkecie lub na targu, las to nie dla mnie – jeszcze można się zgubić i złapać kleszcza.</label>
          </div>

          <div className="control">
          <input  className="4" onInput={setAnswer} type="checkbox" name="4B" id="4B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="4B">b) Z całą rodziną jesteśmy w lesie jeszcze przed pierwszymi promieniami słońca, nie możemy pozwolić, żeby nasza sekretna miejscówka wpadła w niepowołane ręce.</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="4" onInput={setAnswer} type="checkbox" name="4C" id="4C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="4C">c) Nie ważne czy uda się coś znaleźć, ważne że z przyjaciółmi.</label>
          </div>

          <div className="control">
          <input  className="4" onInput={setAnswer} type="checkbox" name="4D" id="4D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="4D">d) Od pierwszego wejrzenia w grzybnię na Instagramie nie mogę przestać hodować boczniaków na oknie. </label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q5" />
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Pytanie 5:<br /> Jak radzisz sobie ze stresem?</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="5" onInput={setAnswer} type="checkbox" name="5A" id="5A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="5A">a) "Nie radzę sobie ze stresem, to stres radzi sobie ze mną" - Woody Allen.</label>
          </div>

          <div className="control">
          <input  className="5" onInput={setAnswer} type="checkbox" name="5B" id="5B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="5B">b) "Radzę sobie ze stresem tak samo, jak radzą sobie z nim moje rośliny doniczkowe – kiedy zaczynam zbyt mocno narzucać im swoje oczekiwania, po prostu je podlewam i zostawiam same sobie" - Deepak Chopra.</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="5" onInput={setAnswer} type="checkbox" name="5C" id="5C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="5C">c) "Radzę sobie ze stresem, układając swoje problemy w kolejności ważności: od najważniejszych do najmniej ważnych - i potem ignorując je wszystkie" - Timothy Leary.</label>
          </div>

          <div className="control">
          <input  className="5" onInput={setAnswer} type="checkbox" name="5D" id="5D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="5D">d) Zgniatam folię bąbelkową.</label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q6" />
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Pytanie 6:<br /> Który cytat o sensie życia najbardziej do Ciebie trafia?</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="6" onInput={setAnswer} type="checkbox" name="6A" id="6A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="6A">a) "Sens życia polega na ciągłym przeciwstawianiu się jego bezsensowi" – Albert Camus</label>
          </div>

          <div className="control">
          <input  className="6" onInput={setAnswer} type="checkbox" name="6B" id="6B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="6B">b) "Sens życia to znalezienie sposobu na żywienie się i spanie, żeby mieć czas na robienie rzeczy, które naprawdę lubimy" – Bill Murray</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="6" onInput={setAnswer} type="checkbox" name="6C" id="6C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="6C">c) "Sens życia to jak kręcenie kiełbaski, najlepiej robić to powoli i uważnie, żeby się nie przekręcić" – Tom Robbins</label>
          </div>

          <div className="control">
          <input  className="6" onInput={setAnswer} type="checkbox" name="6D" id="6D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="6D">d) "Sens życia to zdolność do przetrwania w obliczu absurdalnej rzeczywistości" – Frank Zappa</label>
          </div>
        </div>
      </div>
      </div>

      <div style={{textAlign:'center',paddingBottom:'50px'}}>
      <button className="button" onClick={checkAnswers}>Sprawdź wynik...</button>
      </div>


      <div className="hero-body" id="answers" style={{display:'none'}}>
      <h2>Twój grzyb to:</h2>
        <div className="test-answer" id="an1">
          <h3 className="title">Czarna Trufla</h3>
          <p>Jeśli jesteś jak czarna trufla to musisz być równie ekskluzywną i wyjątkową osobą! Przepełnia Cię energia i lubisz mieć kontrolę nad swoim życiem, ale zawsze masz czas dla swoich przyjaciół i rodziny. Jesteś osobą niezwykle wymagającą w kwestii swojego stylu życia i wybierającą tylko najlepsze rzeczy, od ubrań po jedzenie. Możesz być trudno Cię znaleźć, ale gdy już to się uda, zapadasz w pamięć dzięki swojemu niezwykłemu i wyjątkowemu charakterowi. W każdym razie, taka osoba jak Ty jest cenna jak złoto, tak jak czarna trufla!</p>
          <br />
        </div>
        <div className="test-answer" id="an2">
          <h3 className="title">Prawdziwek:</h3>
          <p>Jesteś jak prawdziwek, bo zawsze znajdujesz się na swoim miejscu i nie pozwalasz, aby cokolwiek Cię rozproszyło. Podobnie jak prawdziwek, jesteś wytrzymały i odporny na trudne warunki, które życie Ci stawia. Nie boisz się pokazać swojej prawdziwej natury, tak jak ten grzyb nie boi się wychodzić na światło dzienne i eksponować swojego charakterystycznego koloru i kształtu. Jesteś prawdziwy jak prawdziwek, bo zawsze jesteś sobą i nie udajesz kogoś, kim nie jesteś.</p>
          <br />
        </div>
        <div className="test-answer" id="an3">
          <h3 className="title">Boczniak cytrynowy</h3>
          <p>Jesteś jak Boczniak cytrynowy, a Twój charakter jest jak smak tego grzyba – orzeźwiający i pełen energii! Jesteś radosną, łatwo nawiązującą kontakty z innymi osobą, a Twoje pozytywne podejście do życia jest jak witamina C dla duszy. Jednak tak jak Boczniak cytrynowy, czasem potrzebujesz odrobiny samotności, aby się zregenerować i rozwijać swoją wyjątkową osobowość.</p>
          <br />
        </div>
        <div className="test-answer"  id="an4">
          <h3 className="title">Amanita Muscaria</h3>
          <p>Jeśli jesteś jak Amanita Muscaria, masz charakter jak ten grzyb – trochę ekscentryczny oraz pełen niespodzianek! Jesteś osobą kreatywną, pełną energii i uwielbiającą przygody i wyzwania intelektualne. Często robisz rzeczy na własną rękę, nie boisz się eksperymentować, sięgać po nowe i potrafisz zaskoczyć innych swoją odwagą i szerokimi horyzontami. Jednak tak jak Amanita Muscaria, czasem może być trudno Cię odczytać i trzeba uważać, żeby Cię nie podgrzybić!</p>
          <br />
        </div>
      </div>

      <div id="retake" style={{textAlign:'center',paddingBottom:'50px',display:'none'}}>
      <a href="#q1" className="button" onClick={clearTest}>Odpowiedz ponownie...</a>
      </div>

  </section>
    </>
  );
};


export default Psychotest;
